export default {
    name: 'disciplinary',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        disciplinaryActionTypes: async (context) => {
            return axios.get(disciplinary+`/disciplinaryActionTypes`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        disciplinary: async (context,payload) => {
            return axios.post(  `${disciplinary}/create`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        },
        disciplinaryRequests: async (context,payload) => {
            const range = context.getters.getGlobalDateRange;
            return axios.get(`${disciplinary}/disciplinaryRecords?username=${payload.username}&actionType=${payload.actionType}&from=${range.from}&to=${range.to}`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
