import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/fonts/icons.js'
import router from './router'
import store from './store'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/style.scss'
import './services/GlobalVariables'
import { initOneSignal } from './plugins/onesignal';
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueToggles from 'vue-toggles';
import Loading from 'vue-loading-overlay';
import {VueEditor} from "vue2-editor";
import FullCalendar from 'vue-full-calendar';

Vue.use(FullCalendar)

import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css'


// Import stylesheet
Vue.use(VueGoodTablePlugin);
import DrawerLayout from 'vue-drawer-layout'
import 'vue-loading-overlay/dist/vue-loading.css';

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import '@js-joda/core'
import VueCal from 'vue-cal'
import gallery from 'img-vuer'


Vue.use(gallery, {
    swipeThreshold: 150, // default 100
    isIndexShow: true, // show image index, default true

})


Vue.component('vue-cal', VueCal)
Vue.component('vue-editor', VueEditor)
Vue.component('v-select', vSelect)
Vue.component('loading', Loading);
Vue.component('VueToggles', VueToggles);
Vue.use(VueVirtualScroller)
Vue.use(DrawerLayout);

Vue.config.productionTip = false
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue);

new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        initOneSignal(`HelpDesk`); // Initialize OneSignal here
    },
}).$mount('#app')
