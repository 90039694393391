// src/onesignal.js
export const initOneSignal = async (deviceId) => {
    window.OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
        // Initialize OneSignal
        OneSignal.init({
            appId: "f3deb813-cfe8-4acd-9773-8d72d96e14bb", // Replace with your OneSignal App ID
            serviceWorkerPath: "/OneSignalSDKWorker.js", // Path to your service worker
            allowLocalhostAsSecureOrigin: true, // Allow localhost during development
            notifyButton: {
                enable: true, // Enable notification button
                size: "medium", // Button size
                position: "bottom-right", // Button position
                theme: "default", // Button theme
            },
        });

        // Add event listener for push subscription changes
        const pushSubscriptionChangeListener = async (event) => {
            try {
                console.log("Push Subscription Event:", event);

                if (event.current.token) {
                    console.log("New Subscription Token:", event.current.token);

                    // Associate subscription with custom device ID
                    OneSignal.setExternalUserId(deviceId, () => {
                        console.log("Device ID set in OneSignal:", deviceId);
                    });
                }
            } catch (error) {
                console.error("Error in pushSubscriptionChangeListener:", error);
            }
        };

        // Listen for subscription changes
        OneSignal.User.PushSubscription.addEventListener(
            "change",
            pushSubscriptionChangeListener
        );

        // Check if notifications are enabled
        OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
            console.log("Push Notifications Enabled:", isEnabled);
        });

        // Get Player ID (Optional)
        OneSignal.getUserId().then((playerId) => {
            console.log("OneSignal Player ID:", playerId);
        });
    });
};
