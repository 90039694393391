import store from "../store/index"
import {dateTimeManager} from "@/services/TimeManager";
const mimeTypeToExtensionMap = {
    // Image Formats
    "application/pdf": ".pdf",
    "image/jpeg": ".jpg",
    "image/png": ".png",
    "image/gif": ".gif",
    "image/bmp": ".bmp",
    "image/svg+xml": ".svg",
    "image/tiff": ".tiff",
    "image/webp": ".webp",
    "image/heif": ".heif", // High Efficiency Image File Format
    "image/vnd.microsoft.icon": ".ico",

    // Microsoft Office Formats
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
    "application/vnd.ms-excel": ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    "application/vnd.ms-powerpoint": ".ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
    "application/vnd.ms-access": ".mdb",
    "text/csv": ".csv",

    // Text Formats
    "text/plain": ".txt",
    "text/html": ".html",
    "text/css": ".css",
    "text/javascript": ".js",
    "application/json": ".json",
    "text/xml": ".xml",
    "text/markdown": ".md",
    "application/x-yaml": ".yaml",
};
function formatNumber(decimalCount, amount, symbol, thousands = ",", decimal = ".") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        if (!amount || isNaN(amount)) {
            amount = 0;
        }

        const negativeSign = amount < 0 ? "-" : "";

        // Convert the amount to a fixed-point notation
        amount = Number(amount).toFixed(decimalCount);

        // Use regex to remove unnecessary trailing zeros and the decimal point if it is an integer
        let [integerPart, fractionalPart] = amount.split('.');

        // Trimming the fractional part of trailing zeros if necessary
        if (fractionalPart) {
            fractionalPart = fractionalPart.replace(/0+$/, '');
        }

        let i = Math.abs(parseInt(integerPart)).toString(); // Ensure the integer part is positive for the split
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            symbol +
            ' ' +
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (fractionalPart ? decimal + fractionalPart : '')
        );
    } catch (e) {
        console.error(e);
    }
}
export const utils = {
    toPascalCase(string) {
        return string
            .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
            .replace(/^\w/, s => s.toUpperCase());
    },
    cutLength(string, limit = 45) {
        if (string.length < limit) {
            return string
        } else {
            return string.substring(0, limit).concat('...')
        }
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",",isCurrency = true,currency = "") {
        if (!isCurrency || !currency){
            return formatNumber(decimalCount, amount, '', thousands, decimal);
        }
        return formatNumber(decimalCount, amount, currency, thousands, decimal);
    },
    removeIf(arr, predicate) {
        return arr.filter(predicate);
    },
    remove(arr, obj) {
        return arr.filter(v => v !== obj)
    },
    showLoading: () => store.commit(`setLoadingState`, true),
    hideLoading: () => store.commit(`setLoadingState`, false),

    getExtensionByMimeType(mimeType) {
        return mimeTypeToExtensionMap[mimeType] || "";
    },

    displayDate(date) {
        return dateTimeManager.shortenStandardDisplay(date);
    },

}
