import {responses} from "@/utils/responses";
import axios from "axios";
import {Storage} from "@/services/Storage";
import {dateTimeManager} from "@/services/TimeManager";

export default {
    name: 'tickets',
    state: {
        tickets: [],
        ticketCount: 0,
        currentUserTicketCount: 0,
        groupedTickets: [],
        ticketTypes: [],
        ticketStatuses: [],
        currentNotes: [],
        supportTicketCount: 0,
        previousSupportTicketCount: 0,
        supportTickets: [],
    },
    getters: {
        getTickets: (state) => state.tickets,
        getTicketTypes: (state) => state.ticketTypes,
        getSupportTicketCount: (state) => state.supportTicketCount,
        getPreviousCount: (state) => state.previousSupportTicketCount,
        getSupportTickets: (state) => state.supportTickets,
        getFieldsByType: (state) => (payload) => {
            let type = Number(payload.type);
            let subType = Number(payload.subType);
            let ticketType = state.ticketTypes.find(t => t.id === type);
            if (!ticketType) return [];

            let ticketSubType = ticketType.children.find(t => t.id === subType);
            if (!ticketSubType) return [];
            return ticketSubType.fields;
        },
        getOpenTicketCount: (state) => state.ticketCount,
        getCurrentUserTicketCount: (state) => state.currentUserTicketCount,
        getTicketStatuses: (state) => state.ticketStatuses,
        getCurrentNotes: (state) => state.currentNotes,
        getTicketsGroupedByCustomer: (state) => state.groupedTickets
    },
    mutations: {
        setSupportTicketCount: (state, data) => state.supportTicketCount = data,
        setPreviousCount: (state, data) => state.previousSupportTicketCount = data,
        setSupportTickets: (state, data) => state.supportTickets = data,
        setTickets: (state, data) => state.tickets = data,
        setOpenTicketCount: (state, data) => state.ticketCount = data,
        setCurrentUserTicketCount: (state, data) => state.currentUserTicketCount = data,
        setGroupedTickets: (state, data) => state.groupedTickets = data,
        setCurrentNotes: (state, data) => state.currentNotes = data,
        setTicketStatuses: async (state, data) => {
            if (data) {
                await Storage.setStatuses(data);
            } else {
                data = await Storage.getAllTicketStatuses();
            }
            state.ticketStatuses = data
        },
        setTicketTypes: async (state, data) => {
            if (data) {
                await Storage.setTicketTypes(data);
            } else {
                data = await Storage.getAllTicketTypes();
            }
            state.ticketTypes = data
        },
    },
    actions: {
        updateTicketStatus: (context, payload) => {
            return axios.put(ticketApi + `ticket/status/${payload.id}?status=${payload.status}`, {})
                .catch((err) => errorHandler.tomcatError(err))
        },
        deleteTicket: (context, id) => {
            return axios.delete(ticketApi + `ticket/${id}`)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getAllSupportTickets: async (context) => {
            return axios.get(ticketApi + 'ticket/getAllSupportTickets')
                .then(({data}) => {
                    context.commit(`setSupportTickets`,data);
                    context.commit(`setSupportTicketCount`,data.length);
                }).catch(err=>errorHandler.tomcatError(err));
        },
        countSupportTickets: async (context) => {
            return axios.get(ticketApi + 'ticket/countSupportTickets')
                .then(({data}) => {
                    context.commit(`setSupportTicketCount`,data);
                });
        },

        getOpenTicketCount: (context) => {
            return axios.get(ticketApi + `ticket/opencount`)
                .then(({data}) => context.commit('setOpenTicketCount', data.count))
        },

        getCurrentUserTicketCount: async (context) => {
            let user = await Storage.getCurrentUser();
            if (user) {
                user = user.id
                return axios.get(ticketApi + `ticket/opencount/${user}`)
                    .then(({data}) => context.commit('setCurrentUserTicketCount', data.count))
            }
        },

        getAllTicketsPastDue: () => {
            return axios.get(ticketApi + `ticket/PastDue`)
                .then(({data}) => data)
        },

        getUserTicketsPastDue: async (context) => {
            let user = await Storage.getCurrentUser();
            user = user.id;
            return axios.get(ticketApi + `ticket/PastDue/${user}`)
                .then(({data}) => data)
        },

        getAllOpenTickets: (context) => {
            return context.dispatch('ticketSearch', {
                closed: false
            }).then(res => res);
        },

        getAllTicketsDueToday: (context) => {
            return context.dispatch('ticketSearch', {
                closed: false,
                where_between: {
                    Date_Search_Name: "dueDate",
                    Date_Search_from: dateTimeManager.getNow().slice(0, 10),
                    Date_Search_to: dateTimeManager.getTomorrow().slice(0, 10)
                }
            }).then(res => res);
        },

        getAllTicketscreatedToday: (context) => {
            return context.dispatch('ticketSearch', {
                closed: false,
                where_between: {
                    Date_Search_Name: "createdDate",
                    Date_Search_from: dateTimeManager.getNow().slice(0, 10),
                    Date_Search_to: dateTimeManager.getTomorrow().slice(0, 10)
                }
            }).then(res => res);
        },
        searchtickets: (context, payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(SearchTicket + `search`, {
                params: payload
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        ticketSearch: (context, payload) => {
            const {from, to} = context.getters.getGlobalDateRange || {};

            // Add global date range to the searchObj or use query params
            const updatedSearchObj = {
                ...payload,
                from,
                to
            };

            // If you want to append it as query params to the URL:
            const dateRangeParams = `?from=${from}&to=${to}`;

            return axios.post(ticketApi + `ticket/search${dateRangeParams}`, {
                params: payload
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getTicketsByDate: (_, date) => {
            return axios.get(ticketApi + `ticket/v2/dateToday?date=${date}`)
                .then(({data}) => data)
        },

        getAllTicketsGroupedByCustomer: (context) => {
            return axios.get(ticketApi + `ticket/groupbycustomer`)
                .then(({data}) => context.commit('setGroupedTickets', data))
        },
        checkTicket: (context, id) => {
            return axios.get(ticketApi + `ticket/v2/checkTicket/${id}`)
                .then(({data}) => data)
        },


        getTicketByID: (context, id) => {
            return axios.get(ticketApi + `ticket/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getAttachmentsByTicket: (context, id) => {
            return axios.get(ticketApi + `ticket/${id}/attachment/getAttachments`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        openAttachment: (context, obj) => {
            return window.open(ticketApi + `ticket/${obj.ticket}/attachment/${obj.attachmentID}`)
        },

        getAllTicketStatuses: (context) => {
            return axios.get(ticketApi + `ticket/status/all`)
                .then(({data}) => context.commit('setTicketStatuses', data))
        },

        getAllTicketTypes: (context) => {
            return axios.get(ticketApi + `ticket/types`)
                .then(({data}) => context.commit('setTicketTypes', data))
        },

        createTicket: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            payload.createdUser = user.id;
            return axios.post(ticketApi + `ticket`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        updateTicket: async (context, payload) => {
            let id = payload.id;
            return axios.put(ticketApi + `ticket/${id}`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        createNoteView: (context, ticketID) => {
            return Swal.fire({
                allowOutsideClick: false,
                titleText: `Add note`,
                inputAutoTrim: true,
                showCancelButton: true,
                showLoaderOnConfirm: true,
                html: `
                        <div class="d-flex flex-column align-items-start gap-1 p-1">
                            <h4 >Ticket#${ticketID}</h4>
                            <div class="form-check form-switch">
                                <input class="form-check-input" checked type="checkbox" role="switch" id="isInternal">
                                <label class="form-check-label" for="isInternal">internal</label>
                            </div>
                            <textarea class="w-100 form-control" id="note" placeholder="Add Comment..." rows="6" >N/A</textarea>
                        </div>
                        `,
                focusConfirm: false,
                confirmButtonText: 'Save',
                preConfirm: async () => {
                    let note = Swal.getPopup().querySelector('#note').value
                    let isInternal = Swal.getPopup().querySelector('#isInternal').checked;
                    if (!note) {
                        return Swal.showValidationMessage(`Please add a validate comment`);
                    }
                    let user = await Storage.getCurrentUser();
                    return context.dispatch('addNoteToTicket', {
                        Note: note,
                        createduser: user,
                        internal: isInternal,
                        ticketid: Number(ticketID)
                    });
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire({
                        title: `Success`,
                        icon: 'success',
                        text: `Done`
                    })
                }
            })
        },
        addNoteToTicket: async (context, payload) => {
            return context.dispatch('createNote', payload)
                .then(async () => {
                    let ticket = await context.dispatch('getTicketByID', payload.ticketid)
                    let ticketNotes = ticket.notes;
                    context.commit('setCurrentNotes', ticketNotes)
                }).catch((err) => errorHandler.tomcatError(err))
        },
        createNote: async (context, payload) => {
            return axios.post(ticketApi + `ticket/notes`, payload)
                .catch((err) => errorHandler.tomcatError(err))
        },
        updateTicketNote: async (context, payload) => {
            return axios.put(ticketApi + `ticket/notes/${payload.id}`, payload)
                .then(async () => {
                    let ticket = await context.dispatch('getTicketByID', payload.ticketid)
                    let ticketNotes = ticket.notes;
                    context.commit('setCurrentNotes', ticketNotes)
                }).catch((err) => errorHandler.tomcatError(err))
        },
        getTicketsByUserID: (context, id) => {
            return axios.get(ticketApi + `ticket/byuser/${id}`)
                .then(({data}) => data.filter(ticket => !ticket.closed))
                .catch((err) => errorHandler.tomcatError(err))
        },
        getTicketsByUser: (context, {userName, pastDue = false}) => {
            return axios.get(ticketApi + `ticket/v2/getByUser?user=${userName}&pastDue=${pastDue}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        openMaps: (context, location = `https://maps.google.com/`) => {
            return window.open(location, '_blank');
        },
        closeTicketDialog: (context, ticketID) => {
            return Swal.fire({
                allowOutsideClick: false,
                titleText: `Closing note`,
                inputAutoTrim: true,
                showCancelButton: false,
                showLoaderOnConfirm: true,
                html: `
                        <div class="d-flex flex-column align-items-start gap-1 p-1">
                            <h4 >Ticket#${ticketID}</h4>
                           <textarea class="w-100 form-control" id="note" placeholder="Add Comment..." rows="6" >N/A</textarea>
                        </div>
                        `,
                focusConfirm: false,
                confirmButtonText: 'Save',
                preConfirm: async () => {
                    let user = await Storage.getCurrentUser();
                    let note = Swal.getPopup().querySelector('#note').value
                    if (!note) {
                        note = "N/A";
                    }
                    return context.dispatch('closeTicketApi', {
                        Note: note,
                        createduser: user,
                        internal: false,
                        ticketid: Number(ticketID)
                    }).catch(() => Swal.showValidationMessage(`Failed To Post Closing Note`));
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    return Swal.fire({
                        title: `Ticket ${ticketID} closed Successfully`,
                        icon: 'success',
                        text: `Done`
                    })
                }
            })
        },

        closeTicketApi: (context, obj) => {
            return axios.post(ticketApi + `ticket/close/${obj.ticketid}`, obj)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        addDocumentToTicket: async (context, obj) => {
            let user = await Storage.getCurrentUser();
            obj.attached_by = user.Username + ` (Staff)`
            return axios.post(ticketApi + `ticket/${obj.ticketID}/attachment/uploadFile`, obj)
                .catch((err) => errorHandler.tomcatError(err))
        },
        deleteDocumentFromTicket: async (context, obj) => {
            return axios.delete(ticketApi + `ticket/${obj.ticket}/attachment/removeFile/${obj.attachmentID}`)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
