export default {
    name: 'leave',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        leaveTypes: async (context) => {
            return axios.get(leave+`/leaveTypes`)
                .then(({data})=>data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        leaveRequests: async (context,payload) => {
            const range = context.getters.getGlobalDateRange;
            return axios.get(leave+`/requests?status=${payload.status}&leaveType=${payload.leaveType}&userID=${payload.userID}&from=${range.from}&to=${range.to}`)
                .then(({data})=> data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        leave: async (context,payload) => {
            return axios.post(  `${leave}`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        },

        leaveApprove: async (context,id) => {
            return axios.post(  `${leave}/approve/${id}`)
                .catch((err) => errorHandler.tomcatError(err))
        },

        leaveDecline: async (context,{id,reason}) => {
            return axios.post(  `${leave}/decline/${id}?declineReason=${reason}`)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
