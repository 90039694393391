<template>
  <div class="container-fluid navigation-bar p-1 px-2">
    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>
    <div class="navbar-logo"></div>

    <div class="navigation-box">
      <button class="burger mx-2" @click="showTicketPopup" v-if="getSupportTicketCount > 0">
        <span class="notification-badge bg-warning">{{ getSupportTicketCount }} Tickets</span>
        <font-awesome-icon icon="bell"/>
      </button>
    </div>

    <custom-modal ref="supportTickets" title="Pending Support Tickets" size="lg">
      <ul class="list-unstyled">
        <li v-for="ticket in getSupportTickets" :key="ticket.id+`-mobile`" class="card mb-2">
          <div class="card-header d-flex flex-row justify-content-between">
            Ticket #{{ ticket.id }}
            <div class="d-flex justify-content-end">
              <a class="btn btn-sm mx-3 btn-outline-info" @click="viewTicket(ticket.id)">View</a>
              <a class="btn btn-sm btn-primary" @click="handleClaimTicket(ticket.id)">Claim</a>
            </div>
          </div>
          <div class="card-body d-flex flex-column">
            <span>{{ ticket.title }}</span>
            <span>{{ ticket.client ? ticket.client : 'No Client Specified' }}</span>
            {{ ticket.store ? ticket.store : 'No Store Specified' }}
            <span>Created : {{ $utils.displayDate(ticket.createdDate) }}</span>
            <span>Due Date : {{ $utils.displayDate(ticket.dueDate) }}</span>
            <div class="d-flex flex-row justify-content-evenly">

            </div>
          </div>
        </li>
      </ul>
    </custom-modal>
    <audio ref="dingSound" src="@/assets/notification.mp3"></audio>
    <div class="user-settings my-2">

      <button @click="findTicket" class="burger mx-3">
        <font-awesome-icon icon="magnifying-glass"/>
      </button>

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate"/>
      </button>
    </div>

    <custom-modal ref="ticketFinder" title="Ticket Finder" :is-form="true" @submit="checkTicket">
      <p class="text-primary text-sm">NB: Type a ticket number to quickly open it</p>

      <label for="checkTicketNumber" class="fw-bold">Ticket #</label>
      <input type="number" class="form-control" v-model="ticketNumber" id="checkTicketNumber" ref="tickNumber">
    </custom-modal>
  </div>
</template>
<script>
import CustomModal from "@/components/layout/CustomModal";
import Swal from "sweetalert2";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavigationBar",
  components: {CustomModal},
  data() {
    return {
      previousTicketCount: 0,
      ticketNumber: ``,
      onlineStatusInterval: null,
      ticketCountInterval: null,
    }
  },
  props: {
    toggle: {
      type: Function,
      require: true,
    },
  },
  computed: {
    ...mapGetters(["getSupportTicketCount"]),
    ...mapGetters(["getSupportTickets"]),
  },
  mounted() {
    // Connect to the WebSocket server
    // this.connectWebSocket();
  },
  methods: {
    syncData() {
      this.$utils.showLoading();
      return window.location.reload(true);
    },
    async showTicketPopup() {
      if (this.getSupportTicketCount > 0) {
        await this.$store.dispatch(`getAllSupportTickets`);
        this.$refs.supportTickets.openModal();
      } else {
        Swal.fire({
          icon: 'info',
          title: 'No pending tickets available!',
          timer: 2000,
          showConfirmButton: false
        });
      }
    },

    ...mapActions(["connectWebSocket"]),
    // showTicketPopup() {
    //   if (this.getSupportTicketCount > 0) {
    //     this.$refs.supportTickets.openModal();
    //   } else {
    //     Swal.fire({
    //       icon: "info",
    //       title: "No pending tickets available!",
    //       timer: 2000,
    //       showConfirmButton: false,
    //     });
    //   }
    // },

    async handleClaimTicket(ticketId) {
      return this.claimTicket(ticketId).then(() => {
        return Swal.fire({
          icon: 'success',
          title: 'Claimed ticket successfully!',
          timer: 2000,
          showConfirmButton: false
        }).then(() => this.fetchAvailableTickets());
      });
    },
    viewTicket(ticketId) {
      if (this.$route.name === 'view-ticket') {
        return this.$router.replace({ name: 'view-ticket', params: { ticketId } })
            .then(()=>window.location.reload(true));
      }
      return this.$router.push({name: 'view-ticket', params: {ticketId}})
    },

    findTicket() {
      this.$refs.ticketFinder.openModal();
      setTimeout(() => {
        document.getElementById('checkTicketNumber').focus();
      }, 500);
    },
    checkTicket() {
      this.$utils.showLoading();
      return this.$store.dispatch(`checkTicket`, this.ticketNumber)
          .then(async (res) => {
            if (res) {
              this.viewTicket(this.ticketNumber)
              this.ticketNumber = ``
              return;
            }
            await responses.showWarning(`Ticket ${this.ticketNumber} not found`);
            return this.findTicket();
          }).finally(() => this.$utils.hideLoading());
    },
    updateUserOnlineStatus() {
      return this.$store.dispatch(`updateUserOnlineStatus`)
    },
    async fetchAvailableTickets() {
      this.$utils.showLoading();
      let currentCount = this.$store.getters.getSupportTicketCount;
      return this.$store.dispatch(`getAllSupportTickets`)
          .then(()=>{
            let newCount = this.$store.getters.getSupportTicketCount;
            if (currentCount < newCount) this.playDingSound();
          }).finally(()=>this.$utils.hideLoading());
    },
    async claimTicket(ticketNumber) {
      try {
        let user = this.$store.getters.getCurrentUser;
        const userID = user.id;
        await axios.put(ticketApiV2 + 'ticket/claimTicket', {
          userID,
          ticketNumber
        }).then(() => {
          this.fetchAvailableTickets();
          this.$store.dispatch(`getPreLoginData`)
        });
      } catch (error) {
        return errorHandler.tomcatError(error)
      }
    },

    playDingSound() {
      this.$refs.dingSound.play();
    },
    startOnlineStatusUpdate() {
      this.onlineStatusInterval = setInterval(async () => {
        await this.updateUserOnlineStatus();
      }, 60000 * 10); // 60000ms = 1 minute * 30 = 30 minute
    },
    startSupportUpdate() {
      this.ticketCountInterval = setInterval(async () => {
        await this.$store.dispatch(`countSupportTickets`);
      }, 30000); // 60000ms = 1 minute * 30 = 30 minute
    },
  },
  watch:{
    getSupportTicketCount(newCount,currentCount){
      if (currentCount < newCount) this.playDingSound();
    },
  },
  beforeDestroy() {
    clearInterval(this.onlineStatusInterval);
    clearInterval(this.ticketCountInterval);
  },
  async created() {
    await this.updateUserOnlineStatus();
    this.startOnlineStatusUpdate();
    this.startSupportUpdate();
  }
};
</script>
<style lang="scss" scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

button.btn {
  margin: 5px;
}

.red-blinking {
  color: red;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.notification-badge {
  position: fixed;
  margin-right: 5px;
  color: white;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  font-size: 12px;
  opacity: 0; /* Initially hidden */
  transform: scale(0); /* Initially small size */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.burger .notification-badge {
  opacity: 1;
  transform: scale(1);
  animation: notification-pulse 1.5s infinite;
}

@keyframes notification-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
}

//@import "src/assets/style/hide-table-mobile";
.text-sm {
  font-size: 12px;
}
</style>
