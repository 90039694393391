import axios from "axios";
import lytSoftLogo from "@/assets/img/use.png";
import lytSILogo from "@/assets/img/si-logo.png";
import {Storage} from "@/services/Storage";
export default {
    name: 'smart-invoice',
    state: {
        productList : [
            {
                id : `1`,
                name : "Lyt-Soft POS",
                imageSrc : lytSoftLogo,
                route : {
                    name : "lytsoft-smart-invoice"
                }
            },
            {
                id : `2`,
                name : "Chrilan Smart Invoice (Lyt-SI)",
                imageSrc : lytSILogo,
                route : {
                    name : "chrilan-smart-invoice"
                }
            },
        ]
    },
    getters: {
        getSmartInvoiceProductList:(state) =>state.productList,
    },
    mutations: {},
    actions: {
        searchSignUps: (context, payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(lytSIApi+`smart-invoice-signup/api/signups/search`,{
                params : payload
            }).then(({data})=>data.response).catch(err=>errorHandler.tomcatError(err))
        },
        searchLytSoftSignUps: (context, payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(ticketApi+`smartInvoiceOnboard/search`,{
                params : payload
            }).then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getChrilanSignUpByID: (context, id) => {
            return axios.get(lytSIApi+`smart-invoice-signup/signup/${id}`,)
                .then(({data})=>data.response).catch(err=>errorHandler.tomcatError(err))
        },
        getChrilanDocByID: (context, id) => {
            return window.open(lytSIApi+`smart-invoice-signup/commitmentform/download-pdf/${id}`);
        },
        getLytSoftSignUpByID: (context, id) => {
            return axios.get(ticketApi+`smartInvoiceOnboard/getByID/${id}`,)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getLytSoftLogsBySignUp: (context, id) => {
            return axios.get(ticketApi+`smartInvoiceOnboard/getSubmissionLogs/${id}`,)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getSILogsBySignUp: (context, id) => {
            return axios.get(lytSIApi+`smart-invoice-signup/signup-logs/${id}`,)
                .then(({data})=>data.response).catch(err=>errorHandler.tomcatError(err))
        },
        getLytSoftBranchesBySignUp: (context, id) => {
            return axios.get(ticketApi+`smartInvoiceOnboard/getStoresSubmitted/${id}`,)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err))
        },
        getSIBranchesBySignUp: (context, id) => {
            return axios.get(lytSIApi+`smart-invoice-signup/signup-branches/${id}`,)
                .then(({data})=>data.response).catch(err=>errorHandler.tomcatError(err))
        },
        markLytSoftSubmissionAsDone: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(ticketApi+`smartInvoiceOnboard/markAsSubmitted`,payload)
                .catch(err=>errorHandler.tomcatError(err,true))
        },
        markSISubmissionAsDone: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            let url =  lytSIApi+`smart-invoice-signup/mark-as-submitted`;
            return context.dispatch(`sendRequest`,{url , payload});
        },
        markSIApprovedAsDone: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            payload.processedBy = user.Username;
            let url =  lytSIApi+`smart-invoice-signup/signup-status/update`;
            return context.dispatch(`sendRequest`,{url , payload});
        },
        markLytSoftApprovalAsDone: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(ticketApi+`smartInvoiceOnboard/markAsApproved`,payload)
                .catch(err=>errorHandler.tomcatError(err,true))
        },
        getSignUpStatuses: () => {
            return axios.get(lytSIApi+`smart-invoice-signup/signup-statuses`)
                .then(({data})=> data.response)
                .catch(err=>errorHandler.tomcatError(err))
        },
        searchZraCategories:async (context , payload) =>{
            return axios.get(franchiseTomcatApi+`category/maindb/searchZRACatgeories?name=`+payload)
                .then(({data})=>data)
        },

        createSINote:(context , payload)=>{
            let url = lytSIApi+`smart-invoice-signup/notes`;
            return context.dispatch(`sendRequest`,{url , payload});
        },
        getSignNotes: (context , signup) => {
            return axios.get(lytSIApi+`smart-invoice-signup/notes-by-signup-id/${signup}`)
                .then(({data})=> data.response)
                .catch(err=>errorHandler.tomcatError(err))
        },
    }
}
