<template>
  <div>
    <loading :active.sync="loading"
             :can-cancel="false"
             :is-full-page="true">
      <div class="d-flex flex-column justify-content-center ">
<!--        <img style="width : 150px;height: 150px" src="" class="rotate"/>-->
        <br>
        <span class="fw-bolder text-center text-dark-emphasis text-uppercase">Please Wait...</span>
        <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </loading>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
  }
}
</script>
<style lang="scss" scoped>
.rotate {
  border-radius: 50%;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes  pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

div#wave {
  position: relative;
  margin-top: 5px;
  text-align: center;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;

  .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    background: #303131;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
</style>