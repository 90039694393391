<template>
  <div class="drawer">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
      </div>
    </div>
    <div class="accordion menu" id="accordionPanelsStayOpenExample">
      <div class="accordion-item" v-if="isUpdateAvailable">
        <a @click="updateApp">
          <font-awesome-icon class="icon text-info" icon="fa-solid fa-exclamation-circle"/>
          <div class="d-flex flex-column text-center">
            Update Is Available
            <span class="text-sm" @click="updateApp">Click to Update</span>
          </div>
        </a>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne">
            Support
          </button>
        </h2>
        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" >
          <div class="accordion-body">
            <router-link @click.native="back" class="route-btn w-100 bg-transparent" :to="{ name : 'user-dashboard' }">
              <font-awesome-icon class="icon" icon="fa-solid fa-chart-simple"/>
              My Dashboard
            </router-link>

            <a @click="createTicket" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
              Create Ticket
            </a>

            <a @click="searchTicket" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="magnifying-glass"/>
              Ticket Search
            </a>

            <a @click="UserPerformance" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="chart-line"/>
              Statistics
            </a>

          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo">
            Project Management
          </button>
        </h2>
        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="openProjectManagement" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="list-check"/>
              Projects
            </a>
            <a @click="navigateToFollowUp" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="pen-to-square"/>
              Make a follow up
            </a>
            <a @click="kycValidation" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clock"/>
              KYC Validation
            </a>

            <a @click="stockDispatch" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="boxes-stacked"/>
              Stock
            </a>

            <a @click="calendar" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="calendar"/>
              Calendar
            </a>
          </div>
        </div>
      </div>

      <a @click="viewCustomers" class="route-btn border w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="users"/>
        Customers
      </a>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree">
            HR / Staff
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="viewStaff" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="address-card"/>
              View Staff
            </a>

            <a @click="requestFunds" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="hand-holding-dollar"/>
              Request Funds
            </a>

            <a @click="requestLeave" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-person-walking-luggage"/>
              Request Leave
            </a>

            <a @click="viewRequestLeave" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-eye"/>
              View Request Leave
            </a>

            <a @click="disciplinary" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-eye"/>
              Disciplinary
            </a>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour">
            Smart Invoice
          </button>
        </h2>
        <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
          <div class="accordion-body">
            <a @click="viewSmartInvoiceSignup" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="address-card"/>
              Signups
            </a>
          </div>
        </div>
      </div>

      <a @click="logOut" class="route-btn border w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="lock"/>
        Logout
      </a>
    </div>
  </div>

</template>
<script>
import axios from "axios";

export default {
  name: "NavigationDrawer",
  props: {
    close: {
      type: Function,
      required: true,
    }
  },

  computed: {
    curUser() {
      let user = this.$store.getters.getCurrentUser;
      if (user) return user;
      return {
        Username: 'Null',
        Type: 'Unknown',
      }
    },
    isUpdateAvailable() {
      return this.$store.getters.getUpdateAvailability;
    },
  },
  mounted() {
    console.log(this.curUser);
  },
  methods: {
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },
    async navigateToFollowUp() {
      this.back();
      return this.$router.push({ name: 'FollowUp' });
    },
    someMethod() {
      console.log(this.curUser, "this is our currently logged in user");
      // other logic...
    },
     createTicket(){
      this.back();
      return this.$router.push({ name : `create-ticket` });
    },

    onlineChecker() {
      this.back();
      return this.$router.push({ name: 'online-checker' });
    },

    searchTicket() {
      this.back();
      this.$router.push({ name: 'search-ticket' })
    },
    openProjectManagement(){
      this.back();
      return this.$router.push({ name : `JobCardManagement` });
    },
    stockDispatch(){
      this.back();
      return this.$router.push({ name : `PendingStock` });
    },

    kycValidation(){
      this.back();
      return this.$router.push({ name : `PendingEvents` });
    },

    calendar(){
      this.back();
      return this.$router.push({ name : `calendar` });
    },
    viewStaff(){
      this.back();
      return this.$router.push({ name : `users` });
    },
    requestFunds(){
      this.back();
      return this.$router.push({ name : `RequestFunds` });
    }
    ,requestLeave(){
      this.back();
      return this.$router.push({ name : `request-leave` });
    },
    viewRequestLeave(){
      this.back();
      return this.$router.push({ name : `view-leave` });
    },
    disciplinary(){
      this.back();
      return this.$router.push({ name : `ViewDisplinary` });
    },
    viewCustomers(){
      this.back();
      return this.$router.push({ name : `customer-list` });
    },
    logOut() {
      this.back();
      return Swal.fire({
        title: 'Are you sure?',
        text: "You are about to be logged out!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Log out!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({name: "login"})
        }
      })
    },
    viewSmartInvoiceSignup(){
      this.back();
      return this.$router.push({ name : `smart-invoice-product-list` })
    },
    UserPerformance(){
      this.back();
      return this.$router.push({ name : `performance` })
    },
    CustomerEx(){
      this.back();
      return this.$router.push({ name : `Customer-experience` })
    },
    updateApp() {
      this.$store.commit(`setUpdateAvailability`, false);
      return window.location.reload(true);
    },
  }
}
</script>

<style lang="scss" scoped>

a {
  &:hover, &.a-exact-active {
    background: #f1f1f1;
    color: #3F8DCD;

    .icon {
      color: #3F8DCD;
    }
  }
}

.warning {
  border-radius: 50%;
  display: inline-flex; /* Ensures that the container can shrink to fit its content */
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  box-shadow: 0 0 0 9px rgba(217, 193, 98, 0); /* Starting with transparent box-shadow */
  transform: scale(1);
  animation: pulse 2s infinite;
  transform-origin: center center; /* Ensures transform scales from the center */
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 9px rgba(217, 193, 98, 1); /* Fully opaque at start */
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 19px rgba(217, 193, 98, 0); /* Fully transparent and expanded shadow */
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 9px rgba(217, 193, 98, 1); /* Return to start */
  }
}

.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #15356E;
          font-weight: 600;
        }
      }

    }
  }
}

.menu {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}

.icon {
  margin-right: 10px;
  color: #606d77;
  font-size: 1.5rem;
}

.route-btn {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: none;
  color: #1c1c1c;
}

#footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.not-footer {
  margin-bottom: 100px;
}

.box-container {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  margin-bottom: 10px; /* Adjust as needed */
}

.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box {
  display: inline-block;
}

.text-white {
  color: white;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.route-btn{
  cursor: pointer;
}

</style>

